<template>
       <div class="ml-3 mr-3">
            <img   src="../../assets/images/new_loader.svg" />
       </div>
</template>

<script>
    export default {
        name : 'CreateVideoLoader'
    }
</script>
